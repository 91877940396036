body {
  margin: 0;
  padding: 0;
  background-color: #F9F9FB;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pane-el {
  background-color: #FFFFFF;
}

.no-underlink a, a {
  color: #1070CA;
  text-decoration: none;
}

.no-underlink-dk a, a {
  color: #234361;
  text-decoration: none;
}

.d-block {
  display: block;
}

.d-hide {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-grid {
	display: flex;
}

/* Header */
.header {
  background-color: #FFFFFF;
}

.the-vettd-logo {
  width: 155px;
}

/* Heading */
.heading-dist {
  position: relative;
  top: -6px;
}

.transition-common {
  transition: all 0.2s ease;
}

/* Messages */
.table-messages {
  border-top: 1px solid #E4E7EB;
  border-bottom: 0;
}
.message-is-unread {
  position: relative;
}
.message-is-unread::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 2px;
  width: 6px;
  height: 6px;
  margin-top: -3px;
  border-radius: 150px;
  background-color: #1070CA;
  z-index: 9999;
}

/* Header, notification */
.notification {
  z-index: 1;
  position: absolute;
  top: 33px;
  margin-left: -10px;
}

/* List Query Labels and Hides/Shows, cards */
.wrap-labels label { 
  margin-bottom: 2px;
  font-size: 10px;
  color: #66788A;
}

.accordion-hide {
  transition: all 0.3s ease;
  overflow: hidden;
  height: 0;
}

.accordion-show {
  transition: all 0.3s ease;
  height: 60px;
  max-height: 75px;
  overflow: visible;
}

.card-hover {
  border: 1px solid #FFFFFF;
}

.card-hover:hover {
  box-shadow: 0px 2px 8px rgba(67, 90, 111, 0.2);
  background-color: #F9F9FB;
}

/* Footer */
.footer .footer-link {
  font-size: 12px;
  text-decoration: underline;
  color: #234361;
}

.footer .footer-link:hover {
  color: #234361;
}

@media (max-width: 750px) {

  .the-vettd-logo {
    padding-top: 3px;
    width: 105px;
  }
  .flex-grid {
    flex-basis: 100% !important;
    flex-direction: column !important;
  }
  h1 {
    font-size: 24px !important;
  }
  .m-pane-out {
    padding: 5px !important;
  }
  .m-pane-el {
    padding: 20px !important;
  }
  
  /* Footer */
  .footer {
    text-align: center;
  }

  .footer img {
    padding-bottom: 10px;
    width: 75%;
    text-align: center;
  }

  .footer ul {
    margin-bottom: 10px;
  }

  .footer li {
    display: inline;
  }
}